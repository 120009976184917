export default {
  unit: {
    day: 'day | day(s)',
    month: 'm | month | {n} months',
    year: 'year | years | {n} years',
    currency: '${0}',
    currencySign: '$',
    discount: '{0}%off'
  }
};
