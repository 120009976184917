import Vue from 'vue';
import vuetify from './plugins/vuetifyExt';
import App from './App.vue';
import './permission';
import router from './router';
import store from './store';
// import VueMeta from 'vue-meta'
import i18n from '@/i18n';
import './plugins';
// import 'font-awesome/css/font-awesome.min.css'
import '@/assets/css/base/index.scss';

Vue.config.productionTip = false;

// Vue.use(VueMeta, {
//     refreshOnceOnNavigation: true
// })

new Vue({
  router,
  store,
  i18n,
  vuetify,
  beforeCreate() {
    // Install the global event bus
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App)
}).$mount('#app');
