<template>
  <div style="display: none" v-if="ready">
    <iframe :src="sso + '/?is_staff=1'"></iframe>
  </div>
</template>
<script>
import { login } from '@/module/service/oAuth';
import cookie from '@/module/utils/cookie';
import { domain } from '@/module/constant';
import { mapMutations } from 'vuex';
export default {
  name: 'Token',
  data() {
    return {
      sso: domain.SSO,
      ready: false
    };
  },
  methods: {
    getMessage({ origin, data }) {
      let { res } = data;
      if (this.sso !== origin) return;
      res
        ? this._saveToken(data, () => {
            this.$emit('login', data);
          })
        : login();
    },
    _saveToken({ token, user, id }, cb) {
      cookie.remove('staff_token');
      cookie.set('staff_token', token);
      this.setOAuth && this.setOAuth({ staff_token: token, user, userId: id });
      cb();
    },
    ...mapMutations('oAuth', ['setOAuth'])
  },
  mounted() {
    this.ready = true;
  },
  created() {
    window.onmessage = this.getMessage;
  }
};
</script>
