export default {
  unit: {
    day: '天 | 天',
    month: '月 | 月 | {n}个月',
    year: '年 | 年 | {n}年',
    currency: '￥{0}',
    currencySign: '￥',
    discount: '{0}折'
  }
};
