// var moment = require('moment-timezone');
// import dayjs from 'dayjs'
// import 'dayjs/locale/en'
// import utc from 'dayjs/plugin/utc'

// dayjs.extend(utc)
// dayjs.locale('en')

let locale = process.env.VUE_APP_I18N_LOCALE;
let tz = locale === 'zh_CN' ? 'Asia/Shanghai' : 'America/Chicago';

const formatDate = (date, formatStr, isForce) => {
  // let mFormat = ''
  // if(formatStr){
  //     let [,b] = formatStr.split(' ')
  //     mFormat = b?` ${b}`:''
  // }
  let defaultFormat = locale === 'zh_CN' ? 'MM/DD/YYYY' : 'MM/DD/YYYY';
  let tz = locale === 'zh_CN' ? 'Asia/Shanghai' : 'America/Chicago';
  // let format = isForce? formatStr : `${defaultFormat}${mFormat}`
  let format = isForce ? formatStr : `${formatStr || defaultFormat}`;
  return window.moment(date).tz(tz).format(format);
};

// wait time format, parameter: seconds
const formatIdleTime = (second) => {
  second = Math.trunc(second);
  // greater than one day
  if (second > 3600 * 24) {
    const day = parseInt(second / (3600 * 24));
    const hour = parseInt((second % (3600 * 24)) / 3600);
    return `${day}d ${hour}h`;
  } else if (second > 3600) {
    // greater than one hour
    const hour = parseInt(second / 3600);
    const minute = parseInt((second % 3600) / 60);
    return `${hour}h ${minute}m`;
  } else if (second > 60) {
    // greater than one minute
    const minute = parseInt(second / 60);
    const s = parseInt(second % 60);
    return `${minute}m ${s}s`;
  } else {
    // less than one minute
    return `${second}s`;
  }
};

const createDate = (date) => {
  return window.moment(date).tz('America/Chicago');
};

const dateFormatted = (date, split) => {
  if (!split) return;
  if (split === '-') {
    const [year, month, day] = date.split(split);
    return `${month}/${day}/${year}`;
  } else {
    const [month, day, year] = date.split(split);
    return `${year}-${month}-${day}`;
  }
};

// seconds to time
const formatSecond = (second) => {
  if (second <= 0) {
    return '';
  }
  let hour = Math.floor(second / 3600);
  let minute = Math.floor((second % 3600) / 60);
  let sec = second % 60;
  let time = '';
  if (hour) {
    time += `${hour}h `;
  }
  if (minute) {
    time += `${minute}m`;
  }
  if (!hour) {
    time += ` ${sec}s`;
  }
  return time;
};

// get month start date and end date
const getMonthDate = () => {
  const start = window.moment().tz(tz).startOf('month').format('YYYY-MM-DD');
  const end = window.moment().tz(tz).format('YYYY-MM-DD');
  return [start, end];
};

// get some days ago date
export function getDaysAgoDateString(days = 0) {
  return window.moment().tz(tz).subtract(days, 'days').format('YYYY-MM-DD');
}

export { formatDate, createDate, dateFormatted, formatIdleTime, formatSecond, getMonthDate };
// export default dayjs
